import React from 'react'
import tw from 'twin.macro'
import { useStateContext } from '@context/stateContext'
import { JoinUsSection, ProjectsList, Subheader } from '@modules'
import { Spacer } from '@elements'

const Projects = () => {
  const {
    content: {
      pages: {
        projects: { sections },
      },
    },
  } = useStateContext()

  return (
    <Container>
      <Subheader data={sections.subheader} />
      <Spacer className="pb-10 md:pb-20 lg:pb-40 xl:pb-40" />
      <ProjectsList data={sections.projects} />
      <Spacer className="relative z-50 pb-9 md:pb-12 lg:pb-24 xl:pb-24" />
      <JoinUsSection data={sections.joinUs} />
    </Container>
  )
}

export default Projects

const Container = tw.div`w-full bg-background overflow-x-hidden`
